export const data = [
  {
    "id": "00001",
    "supplier": "1982 FOOD & COFFEE SUBIACO ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Food"
  },
  {
    "id": "00002",
    "supplier": "7 Eleven",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-National if snacks were bought on the convenience store"
  },
  {
    "id": "00003",
    "supplier": "96.9 Rock FM Association Inc.",
    "code": "401.1",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00004",
    "supplier": "99 Designed.com",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00005",
    "supplier": "A1 LOCKSMITHS MORLEY ",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00006",
    "supplier": "AAMI",
    "code": "449.03",
    "account": "M/V - Insurance",
    "gst": "GST on Expense",
    "notes": "Can be car/life/ IP insurance"
  },
  {
    "id": "00007",
    "supplier": "AAPM",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00008",
    "supplier": "AB Cleaning Services",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00009",
    "supplier": "Aboriginal Education Council",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00010",
    "supplier": "Account Fee",
    "code": "404",
    "account": "Bank Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00011",
    "supplier": "ACRRM",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00012",
    "supplier": "Adobe System Pty Ltd",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "depends on the purpose - sometimes it is personal"
  },
  {
    "id": "00013",
    "supplier": "ADOP Communications",
    "code": "489",
    "account": "Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00014",
    "supplier": "Advance Plants Services",
    "code": "None",
    "account": "Hire of Plant & Equipment",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00015",
    "supplier": "Advantage Signs",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00016",
    "supplier": "Agoda International",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-International/Conferences"
  },
  {
    "id": "00017",
    "supplier": "AHM",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00018",
    "supplier": "AHPRA",
    "code": "485",
    "account": "Subscription",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00019",
    "supplier": "AIA Australia",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00020",
    "supplier": "AIA Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00021",
    "supplier": "AIA Limited",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor) Sometimes you can see AIA Health Insurance as a description"
  },
  {
    "id": "00022",
    "supplier": "AIRBNB",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00023",
    "supplier": "AIRPORTRENTALS.COM ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00024",
    "supplier": "Alfred Health",
    "code": "230/231",
    "account": "Patient Fees - GST/GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00025",
    "supplier": "Alfred Health Parking",
    "code": "459",
    "account": "Parking Fee",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00026",
    "supplier": "Allianz Australia",
    "code": "433.02",
    "account": "Insurance - Workers Compensation",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00027",
    "supplier": "Allianz Insurance",
    "code": "449.03",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00028",
    "supplier": "Allianz Worker ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00029",
    "supplier": "AM Enterprises",
    "code": "461",
    "account": "Printing and Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00030",
    "supplier": "AMA",
    "code": "485",
    "account": "Subcriptions",
    "gst": "GST on Expense",
    "notes": "memberships subscription"
  },
  {
    "id": "00031",
    "supplier": "AMA Medicus",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "Alternative: Magazine & Periodicals"
  },
  {
    "id": "00032",
    "supplier": "AMA Services (WA) Pty Ltd",
    "code": "447",
    "account": "Medical Supplies",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00033",
    "supplier": "AMACIS",
    "code": "433.02",
    "account": "Insurance - Workers Compensation",
    "gst": "GST on Expense",
    "notes": "Can be any insurance (life, IP) - check with client"
  },
  {
    "id": "00034",
    "supplier": "AMAYSIM",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00035",
    "supplier": "Amazon Marketplace",
    "code": "None",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "can also be coded to Office Expenses, Drawings : Asset immediate/Drawing"
  },
  {
    "id": "00036",
    "supplier": "AMER Soc of Plastic Surgeon",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00037",
    "supplier": "AMP Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00038",
    "supplier": "Ampol",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-National if snacks were bought on the convenience store"
  },
  {
    "id": "00039",
    "supplier": "Anaesthetic Accounts Management",
    "code": "None",
    "account": "Billings Fee",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00040",
    "supplier": "Anglicare WA's School Sleep Out",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00041",
    "supplier": "ANZCA",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00042",
    "supplier": "Apco",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00043",
    "supplier": "APEX ",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00044",
    "supplier": "APNA - ASN",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00045",
    "supplier": "Apple (Apple.com-Bill)",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "This is the cloud storage subscription. \nIf it's a purchase of an asset $300 and up, it will be allocated to Asset  - Immediate Write Off"
  },
  {
    "id": "00046",
    "supplier": "ARN Cleaning",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00047",
    "supplier": "ASIC",
    "code": "423",
    "account": "Filing Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00048",
    "supplier": "Assistance Dogs Australia",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00049",
    "supplier": "Aster POS Australia ",
    "code": "None",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00050",
    "supplier": "Asteron Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00051",
    "supplier": "Asylum Seeker Resource Centre ",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00052",
    "supplier": "Atrium Hotel Mandurah",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00053",
    "supplier": "Audi",
    "code": "None",
    "account": "MV - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00054",
    "supplier": "Aus Net Server",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00055",
    "supplier": "Auscorp IT",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "Toner Cartridge: Alternative - Asset Immediate"
  },
  {
    "id": "00056",
    "supplier": "AUSLMAT ",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00057",
    "supplier": "Aussie Broadband",
    "code": "489",
    "account": "Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00058",
    "supplier": "Aust College Of Dermatologists College fee",
    "code": "None",
    "account": "Subcriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00059",
    "supplier": "Austin Health",
    "code": "230.04",
    "account": "Patient Fees - GST free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00060",
    "supplier": "Austin Service Fees",
    "code": "484.1",
    "account": "Facility Fee/Service Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00061",
    "supplier": "Australasian Academy of Facial Plastic Surgery",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00062",
    "supplier": "Australasian Men's Health Forum (AMHF)",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00063",
    "supplier": "Australia Charity Aid",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00064",
    "supplier": "Australia Post",
    "code": "460",
    "account": "Postage",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00065",
    "supplier": "Australian Automobile Association - AAA",
    "code": "449.01",
    "account": "M/V 1. - Registration",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00066",
    "supplier": "Australian Brandenburg Ensemble Limited (Orcheastra)",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00067",
    "supplier": "Australian Indian Medical Assoc (AIMA) WA ",
    "code": "415",
    "account": "Conferences & Seminars",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00068",
    "supplier": "Australian Indigenous Mentoring Experience (AIME) Donation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00069",
    "supplier": "Australian Medical Nedlands",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00070",
    "supplier": "Australian Phone Company",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00071",
    "supplier": "Australian Sports Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00072",
    "supplier": "Australian Super",
    "code": "826",
    "account": "Superannuation payable",
    "gst": "BAS Excluded",
    "notes": "If paid by client"
  },
  {
    "id": "00073",
    "supplier": "Australian Unity",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00074",
    "supplier": "Auto Excellence",
    "code": "449.07",
    "account": "MV1 Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00075",
    "supplier": "Autobarn Launceston",
    "code": "449.07",
    "account": "MV1 Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00076",
    "supplier": "AutoMed",
    "code": "251",
    "account": "AutoMed Income ",
    "gst": "GST on Income",
    "notes": "Clements Group only"
  },
  {
    "id": "00077",
    "supplier": "Automed Systems Pty Ltd",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "auto messaging/campaign messaging etc"
  },
  {
    "id": "00078",
    "supplier": "AVANT INSURANCE",
    "code": "432",
    "account": "Insurance - Medical Indemnity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00079",
    "supplier": "Avast",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00080",
    "supplier": "Avdata Pty Ltd",
    "code": "403",
    "account": "Airrplane Running Cost",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00081",
    "supplier": "Aware Super",
    "code": "826",
    "account": "Superannuation payable",
    "gst": "BAS Excluded",
    "notes": "If paid by client"
  },
  {
    "id": "00082",
    "supplier": "Bakehouse Harvey",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00083",
    "supplier": "Baker's Delight Subiaco",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00084",
    "supplier": "Bank Fees - Merchant Fee",
    "code": "405",
    "account": "Bank Fee - Merchant Charges",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00085",
    "supplier": "Barbara May Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00086",
    "supplier": "Barrett's Bakery",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "food"
  },
  {
    "id": "00087",
    "supplier": "Bathurst Prestige",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00088",
    "supplier": "BAUER MEDIA PTY",
    "code": "448",
    "account": "Magazines, jourmals, periodicals",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00089",
    "supplier": "Bavarian - Broadbeach ",
    "code": "415",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00090",
    "supplier": "Belong",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00091",
    "supplier": "Bendigo Primary Care",
    "code": "230/231",
    "account": "Patient Fees - GST/GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00092",
    "supplier": "Bendigo Schaller",
    "code": "469",
    "account": "Rent",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00093",
    "supplier": "Bendigo Security",
    "code": "486",
    "account": "Security",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00094",
    "supplier": "Bendigo Telco/ MGR Information Technology",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Sometimes Computer & Software Account if there is any"
  },
  {
    "id": "00095",
    "supplier": "Best Practice Software",
    "code": "416.02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "if it is recurring - code to computer expenses"
  },
  {
    "id": "00096",
    "supplier": "Beyond Blue",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00097",
    "supplier": "Big Fish Technology",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Sometimes Computer & Software Account if there is any"
  },
  {
    "id": "00098",
    "supplier": "BIG W ",
    "code": "429",
    "account": "General Expenses",
    "gst": "GST on Expense",
    "notes": "Alternative: Office Expense"
  },
  {
    "id": "00099",
    "supplier": "Bing Lee Electrics Pty Ltd",
    "code": "473",
    "account": "Repairs and Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00100",
    "supplier": "Biphasic Training",
    "code": "408.01",
    "account": "Staff Training",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00101",
    "supplier": "Bitdefender",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00102",
    "supplier": "Bizcover",
    "code": "433",
    "account": "Insurance - General",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00103",
    "supplier": "BJS insurance Broker Pty Ltd",
    "code": "None",
    "account": "Insurance - General",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00104",
    "supplier": "BKT Services ",
    "code": "400",
    "account": "Accounting and Audit Fees",
    "gst": "GST on Expense",
    "notes": "bookeeping & Accounting"
  },
  {
    "id": "00105",
    "supplier": "Black Dog Institute",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00106",
    "supplier": "Blend Designs",
    "code": "416.02",
    "account": "Asset - Immediate write off",
    "gst": "GST on Expense",
    "notes": "Furniture"
  },
  {
    "id": "00107",
    "supplier": "Blooms the chemist",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00108",
    "supplier": "BMW",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00109",
    "supplier": "BMW Fuel Work Car",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00110",
    "supplier": "BOB JANE T MART",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00111",
    "supplier": "BOC Limited",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "Can also be coded to Materials & Consumables, it depends what account they used in the prev years. Some transactions are GST free."
  },
  {
    "id": "00112",
    "supplier": "Body Shop",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00113",
    "supplier": "BOLLYWOOD RESTAURANT ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00114",
    "supplier": "Bongiorno Partners",
    "code": "400",
    "account": "Accounting & Audit Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00115",
    "supplier": "Booking.com",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If overseas, put to International - no GST"
  },
  {
    "id": "00116",
    "supplier": "BOOST *RECHARGE ",
    "code": "489",
    "account": "Telephone and Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00117",
    "supplier": "BOQ Interest",
    "code": "449.02",
    "account": "MV 1. Interest on Loan",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00118",
    "supplier": "Bose Pty Ltd",
    "code": "416.02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00119",
    "supplier": "Bowel Cancer Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00120",
    "supplier": "BP (Fuel)",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-National"
  },
  {
    "id": "00121",
    "supplier": "Breeze Connect",
    "code": "434",
    "account": "Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00122",
    "supplier": "Brighton Soul",
    "code": "415",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00123",
    "supplier": "Brigthe Capital",
    "code": "419",
    "account": "Electricity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00124",
    "supplier": "Brisbane Boys' College",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00125",
    "supplier": "Broadband",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "Or coded as Internet account alone"
  },
  {
    "id": "00126",
    "supplier": "Broadwat Fair Pharma",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00127",
    "supplier": "BT Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00128",
    "supplier": "Bunnings",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "can be coded as Drawings"
  },
  {
    "id": "00129",
    "supplier": "Bupa Claim",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00130",
    "supplier": "BUPA Defence",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00131",
    "supplier": "Burgess Rawson (WA) Pty Ltd",
    "code": "419",
    "account": "Electricity",
    "gst": "GST on Expense",
    "notes": "Electricity, Strata Levy , Reserve Levy. Alternative: Drawings"
  },
  {
    "id": "00132",
    "supplier": "C Restaurant ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "food"
  },
  {
    "id": "00133",
    "supplier": "Cabrini Health",
    "code": "230/231",
    "account": "Patient Fees - GST/GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00134",
    "supplier": "Cairns Airport Pty Ltd",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00135",
    "supplier": "Caltex",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-National if snacks were bought on the convenience store"
  },
  {
    "id": "00136",
    "supplier": "Camberwell Pharmacy",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "Some are using Medical Supplies"
  },
  {
    "id": "00137",
    "supplier": "Campbelltown Private",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Expense",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00138",
    "supplier": "Canbera Motors",
    "code": "449.07",
    "account": "MV - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00139",
    "supplier": "Cancer Council Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00140",
    "supplier": "CanTeen",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00141",
    "supplier": "Captain Stirling Phcy",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00142",
    "supplier": "Carbon Neutral Charitable Fund",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00143",
    "supplier": "Care Park",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00144",
    "supplier": "Carey Baptist College - Harrisdale",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00145",
    "supplier": "Cartridge World ",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00146",
    "supplier": "CARWASH",
    "code": "449.07",
    "account": "M/V 1. - Repairs and Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00147",
    "supplier": "Castlemaine Office Supplies",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "Office expenses if products are bought for the office, or \ncan also be printing and stationary "
  },
  {
    "id": "00148",
    "supplier": "Catalyst Event",
    "code": "462",
    "account": "Professional Development",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00149",
    "supplier": "CBA CR Card",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00150",
    "supplier": "CBA Merchant Fee",
    "code": "405",
    "account": "Bank Fees Merchant",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00151",
    "supplier": "CBA Transfer",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00152",
    "supplier": "CBHS",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00153",
    "supplier": "CBR Rego Act",
    "code": "449.05",
    "account": "MV - Registration",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00154",
    "supplier": "CC fees",
    "code": "405",
    "account": "Bank Fees - Merchant Charges",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00155",
    "supplier": "CC Payment Thank you",
    "code": "890",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00156",
    "supplier": "CCQ Daffodil Cancer Council",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00157",
    "supplier": "Central Gippsland",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00158",
    "supplier": "Centrelink",
    "code": "None",
    "account": "Paid Parental Leave",
    "gst": "BAS Excluded",
    "notes": "Other Income"
  },
  {
    "id": "00159",
    "supplier": "CENTRO CONGRESSI MILAN MILANO ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00160",
    "supplier": "Cerebral Palsy Alliance",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00161",
    "supplier": "CGU Insurance",
    "code": "449.03",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00162",
    "supplier": "CGU Workers",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00163",
    "supplier": "Chain Reaction Challenge Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00164",
    "supplier": "Chemed Pty Ltd",
    "code": "217.01",
    "account": "Pharmacy - Service Agreement",
    "gst": "GST on Income",
    "notes": "Clements Group"
  },
  {
    "id": "00165",
    "supplier": "Chemist warehouse",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00166",
    "supplier": "Chemistworks",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00167",
    "supplier": "Childfund Australia",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00168",
    "supplier": "Children's Medical Research Institute",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00169",
    "supplier": "CHQ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00170",
    "supplier": "Christian Blind Mission",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00171",
    "supplier": "CIR Payments",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00172",
    "supplier": "Circles Life",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00173",
    "supplier": "Circum Wash",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00174",
    "supplier": "Cirrus Media",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00175",
    "supplier": "City Link",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00176",
    "supplier": "CITY OF PERTH PARKING",
    "code": "459",
    "account": "Parking",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00177",
    "supplier": "Clarendon Med",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00178",
    "supplier": "Cleanaway Daniels Services Pty Ltd",
    "code": "495",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "can be cleaning expense"
  },
  {
    "id": "00179",
    "supplier": "Cleantastic (Li Tang)",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00180",
    "supplier": "ClearViewLife",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00181",
    "supplier": "Clinic to Cloud Pty Ltd",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00182",
    "supplier": "Cliniko",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Sometimes Computer & Software Account if there is any"
  },
  {
    "id": "00183",
    "supplier": "Coalsfield Office",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00184",
    "supplier": "Codi Agency",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00185",
    "supplier": "Coles",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "This is the grocery store, were office supplies is bought, it can also be \nDrawings if supplies  bought is for personal use"
  },
  {
    "id": "00186",
    "supplier": "Coles Express",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00187",
    "supplier": "COLOMBI ALBERTOCARUGATE ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00188",
    "supplier": "Comcare",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00189",
    "supplier": "CommInsure Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00190",
    "supplier": "Community Enterprise Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00191",
    "supplier": "Compassion Australia",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00192",
    "supplier": "Conceptual Communication",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00193",
    "supplier": "Connect East Pty ",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00194",
    "supplier": "Cooper-Lourne FMLY OPT",
    "code": "464",
    "account": "Protective Clothing and Uniforms ",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00195",
    "supplier": "Copp Parking",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00196",
    "supplier": "Cosmetic Journey",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00197",
    "supplier": "Costco ",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "This is the grocery store, were office supplies is bought, it can also be \nDrawings if supplies  bought is for personal use"
  },
  {
    "id": "00198",
    "supplier": "Costco - Fuel station",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00199",
    "supplier": "COSTCO ONLINE SHOPPING",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00200",
    "supplier": "Country Fire Authority (CFA)",
    "code": "447",
    "account": "Medical Supplies/Materials & Consumable ",
    "gst": "GST on Expense",
    "notes": "sometimes GST free depending o what supplies bought (mostly CO2, Dry Chem etc)"
  },
  {
    "id": "00201",
    "supplier": "Coviu.com",
    "code": "None",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00202",
    "supplier": "CRAZY DOMAINS HOSTING",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Sometimes Computer & Software Account if there is any"
  },
  {
    "id": "00203",
    "supplier": "Create a Sign",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "Office expenses if products are bought for the office, or \nprinting and stationary if they were ask to print something, can also be \nadvertising if they are ask to create a poster/signage"
  },
  {
    "id": "00204",
    "supplier": "Crown Towers Perth",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00205",
    "supplier": "CSANZ MEETINGS SYDNEY",
    "code": "415",
    "account": "Conferences & Seminars",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00206",
    "supplier": "CUA Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00207",
    "supplier": "Cubiko",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00208",
    "supplier": "Cure Brain Cancer Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00209",
    "supplier": "Cyberbloom",
    "code": "490",
    "account": "Transcription & Dictation Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00210",
    "supplier": "Cystic Fibrosis Community Care",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00211",
    "supplier": "DANII Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00212",
    "supplier": "Dataworxs Systems",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00213",
    "supplier": "David Jones Limited",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00214",
    "supplier": "DC IT Solutions",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00215",
    "supplier": "DCP Services",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00216",
    "supplier": "Defence Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "can also be coded as Income Holding Account for RCTI clients"
  },
  {
    "id": "00217",
    "supplier": "Deft Insurance",
    "code": "433",
    "account": "Insurance - Medical Indemnity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00218",
    "supplier": "Dennis Computers",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "sometimes to code to asset immediate write off - if asset is purchased"
  },
  {
    "id": "00219",
    "supplier": "DEPARTMENT OF TRANSPORT PERTH AUS",
    "code": "449.05",
    "account": "M/V 1. - Registration",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00220",
    "supplier": "Deposit Interest",
    "code": "270",
    "account": "Interest Income",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00221",
    "supplier": "Deputy.com",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00222",
    "supplier": "Designerplants.com.au",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00223",
    "supplier": "Dextera Pty Ltd",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Remote Support via Phone"
  },
  {
    "id": "00224",
    "supplier": "DHS ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00225",
    "supplier": "Digital Pacific Pty Ltd",
    "code": "438",
    "account": "IT Expenses",
    "gst": "GST on Expense",
    "notes": "can be coded as \"Computer Expenses\""
  },
  {
    "id": "00226",
    "supplier": "Digital Transrip",
    "code": "490",
    "account": "Transcription and dictation fees",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00227",
    "supplier": "DJ Gunn & S Gunn",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00228",
    "supplier": "Doctors Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00229",
    "supplier": "Docu Shred",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "Security Bin"
  },
  {
    "id": "00230",
    "supplier": "Doxy",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00231",
    "supplier": "DRI Trend Micro",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "computer expense"
  },
  {
    "id": "00232",
    "supplier": "Drivers note",
    "code": "449.06",
    "account": "M/V 1. - Other",
    "gst": "GST on Expense",
    "notes": "can also be coded to Computer Expense"
  },
  {
    "id": "00233",
    "supplier": "Dropbox",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00234",
    "supplier": "Dry July Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00235",
    "supplier": "DT Car Care",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00236",
    "supplier": "Duo Magazine",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00237",
    "supplier": "Dusk",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00238",
    "supplier": "DXC Integrated",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00239",
    "supplier": "E-toll Payment",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00240",
    "supplier": "Eagle Petroleum",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00241",
    "supplier": "Eastlink",
    "code": "459",
    "account": "Parking & Tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00242",
    "supplier": "EasyPark Group",
    "code": "459",
    "account": "Parking",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00243",
    "supplier": "EBAY",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Alternative: Asset Immediate "
  },
  {
    "id": "00244",
    "supplier": "Ebos Group Australia",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "Can also be coded to Materials & Consumables, it depends what account they used in the prev years. Some transactions are GST free."
  },
  {
    "id": "00245",
    "supplier": "Ecovis",
    "code": "400",
    "account": "Accounting and Audit Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00246",
    "supplier": "Edvoke Education",
    "code": "462",
    "account": "Professional Development",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00247",
    "supplier": "EFAX Service",
    "code": "461",
    "account": "Printing and Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00248",
    "supplier": "EG Group",
    "code": "449.01",
    "account": "MV1 - Fuel and Oil",
    "gst": "GST on Expense",
    "notes": "can also be coded as Travel-National if snacks were bought on the convenience store"
  },
  {
    "id": "00249",
    "supplier": "Egotek Pty Ltd",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00250",
    "supplier": "ELIXIR COFFEE SPCLST NEDLANDS AUS",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00251",
    "supplier": "Elpol",
    "code": "470",
    "account": "Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "Alternative for Cat2: Drawings"
  },
  {
    "id": "00252",
    "supplier": "Elsevier Australia",
    "code": "448",
    "account": "Magazines, Journals & Periodicals",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00253",
    "supplier": "EML Vic Pty Ltd",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00254",
    "supplier": "Emma Heywood",
    "code": "490",
    "account": "Transcription and dictation fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00255",
    "supplier": "Empire Office Furniture",
    "code": "416.02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00256",
    "supplier": "Employers Mutual",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00257",
    "supplier": "Energy Ease",
    "code": "419",
    "account": "Electricity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00258",
    "supplier": "Ennilles Platter",
    "code": "480.01",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00259",
    "supplier": "Ensign Linen Services",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00260",
    "supplier": "Epworth",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00261",
    "supplier": "Ergon",
    "code": "419",
    "account": "Electricity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00262",
    "supplier": "ESA Clinical Virtual Conference",
    "code": "415",
    "account": "Conferences and Seminars",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00263",
    "supplier": "Espresso Torquay",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00264",
    "supplier": "Estorm",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00265",
    "supplier": "EToll",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Parking & Tolls - if there is an account like this"
  },
  {
    "id": "00266",
    "supplier": "Evolution Copy Print Solutions",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00267",
    "supplier": "ExpressVPN",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "If there's a separate account for Software, allocate to Software Expense."
  },
  {
    "id": "00268",
    "supplier": "Facebook",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "Depends - sometimes its coded to computer expense"
  },
  {
    "id": "00269",
    "supplier": "Falcon Cleaning Services",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00270",
    "supplier": "Farmers Home Hotel",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00271",
    "supplier": "FaxPlus",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00272",
    "supplier": "Feed the Needy",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00273",
    "supplier": "FightMND",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00274",
    "supplier": "Fionas Stanley Hospital",
    "code": "459",
    "account": "Parking Fee",
    "gst": "GST on Expense",
    "notes": "If small amount parking"
  },
  {
    "id": "00275",
    "supplier": "Flexi storage",
    "code": "416.02",
    "account": "Asset - Immediate write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00276",
    "supplier": "Flick Anticimex",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00277",
    "supplier": "Floral Image",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00278",
    "supplier": "Fly on it",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00279",
    "supplier": "Foodbank NSW & ACT",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00280",
    "supplier": "Forbes Accounting",
    "code": "400",
    "account": "Accounting & Audit Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00281",
    "supplier": "Ford Ranger",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00282",
    "supplier": "Foxtel Management Pty Ltd",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "can also be coded as internet or telephone alone depends on the subscription plan"
  },
  {
    "id": "00283",
    "supplier": "Frank Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00284",
    "supplier": "Full Stop Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00285",
    "supplier": "FUNKY FILLINGS CAFEKEW EAST AUS",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00286",
    "supplier": "Gallaher Bassett Insurance",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00287",
    "supplier": "GBS Workers ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00288",
    "supplier": "Geelong Charity Cats Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00289",
    "supplier": "Gen Australian Unity",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "can also be coded as Income Holding Account for RCTI clients"
  },
  {
    "id": "00290",
    "supplier": "Genie Solutions",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00291",
    "supplier": "Geoff States Maya States ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00292",
    "supplier": "GHMBA",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "can also be coded as Income Holding Account for RCTI clients"
  },
  {
    "id": "00293",
    "supplier": "GIO CTP Insurance",
    "code": "449.03",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00294",
    "supplier": "Gio NSW",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00295",
    "supplier": "Global Development Group",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00296",
    "supplier": "GM Cabs Pty Ltd",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\" "
  },
  {
    "id": "00297",
    "supplier": "GMail",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00298",
    "supplier": "GMHBA Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00299",
    "supplier": "Go Fax",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00300",
    "supplier": "GOBERTI CLAUDIOMILANO ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00301",
    "supplier": "Godaddy.com",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "can also be coded as Advertising and Promotion"
  },
  {
    "id": "00302",
    "supplier": "GoFund Me",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Not DGR"
  },
  {
    "id": "00303",
    "supplier": "Golden Wok Innaloo",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00304",
    "supplier": "Good guys",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "can be coded as Drawings"
  },
  {
    "id": "00305",
    "supplier": "Google",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00306",
    "supplier": "GP Support",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "GPsupport has over a decade of experience providing quality medical IT support for Australian healthcare businesses"
  },
  {
    "id": "00307",
    "supplier": "GPMP",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00308",
    "supplier": "Gracie Signs",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00309",
    "supplier": "Grand United",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00310",
    "supplier": "Great Steak",
    "code": "480.01/02",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00311",
    "supplier": "Greenfield Pharmacy",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00312",
    "supplier": "GRILLD PTY LTD",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00313",
    "supplier": "Guide Dogs",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00314",
    "supplier": "Gunnings Pty Ltd",
    "code": "449.07",
    "account": "M/V 1. - Repairs and Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00315",
    "supplier": "Hale Legal",
    "code": "441",
    "account": "Legal expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00316",
    "supplier": "HANNANS MARKET PLACE ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Alternative: Office Expense"
  },
  {
    "id": "00317",
    "supplier": "Harvey Norman",
    "code": "416.02",
    "account": "Asset immediate Write Off",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"office expense\" can also be coded to Computer Expenses"
  },
  {
    "id": "00318",
    "supplier": "HBF",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00319",
    "supplier": "HCF",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00320",
    "supplier": "HEA",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "can also be coded as Income Holding Account for RCTI clients"
  },
  {
    "id": "00321",
    "supplier": "HEALTH INSURANCE ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00322",
    "supplier": "Health Insurance Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00323",
    "supplier": "Health Partners",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00324",
    "supplier": "Healthecare",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00325",
    "supplier": "HEALTHENGINE PTY LTD",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Sometimes Computer & Software Account if there is any"
  },
  {
    "id": "00326",
    "supplier": "HealthLink group",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00327",
    "supplier": "Healthscope Ltd",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00328",
    "supplier": "HealthTrack Software",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00329",
    "supplier": "Heart Research Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00330",
    "supplier": "HERTZ AUSTRALIA PTY",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Car Rental"
  },
  {
    "id": "00331",
    "supplier": "Hervey Bay Chamber of Commerce",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00332",
    "supplier": "Hesta",
    "code": "826",
    "account": "Superannuation payable",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00333",
    "supplier": "HICAPS Fee",
    "code": "405",
    "account": "Bank Fee - Merchant Charges",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00334",
    "supplier": "HICAPS MIS",
    "code": "230",
    "account": "Patient fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "can also be coded as Income Holding Account for RCTI clients"
  },
  {
    "id": "00335",
    "supplier": "HIF",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00336",
    "supplier": "HIGHWYCOMBE FUEL AND HIGHWAY",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00337",
    "supplier": "Hilton Perth Parmelia Perth WA Medical Workshop",
    "code": "462",
    "account": "Professional Development",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00338",
    "supplier": "HiTec Computer Cloud",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00339",
    "supplier": "HK CLeaning Solutions",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00340",
    "supplier": "HMPM PTY LTD",
    "code": "230",
    "account": "Patients Fees - GST Free ",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00341",
    "supplier": "Hollywood Newsagency",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00342",
    "supplier": "Honda",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00343",
    "supplier": "Hopscotch Kitchen",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If minimal amount but if huge like full family meal, drawings"
  },
  {
    "id": "00344",
    "supplier": "Horizen  Computers",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00345",
    "supplier": "Hostelworld.com",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00346",
    "supplier": "Hostgator",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00347",
    "supplier": "Hostplus Superannuation",
    "code": "826",
    "account": "Superannuation payable",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00348",
    "supplier": "HotDoc",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00349",
    "supplier": "HS Reflection",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "new lightning medical suite"
  },
  {
    "id": "00350",
    "supplier": "Hudson Coffee",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "can also be coded to Staff Amenities"
  },
  {
    "id": "00351",
    "supplier": "Hyundai",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00352",
    "supplier": "Ibis Cleaning Services",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00353",
    "supplier": "icare work ",
    "code": "433.02",
    "account": "Insurance - workers compensation",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00354",
    "supplier": "Idameneo",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00355",
    "supplier": "IGA Express",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "check receipt to confirm payment for fuel only"
  },
  {
    "id": "00356",
    "supplier": "Iinet",
    "code": "489",
    "account": "Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00357",
    "supplier": "Ikea",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "can be coded as Drawings"
  },
  {
    "id": "00358",
    "supplier": "Impluse Hand Car Wash",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00359",
    "supplier": "Independence Australia",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00360",
    "supplier": "Infinity Pharmacy Moranbah",
    "code": "447",
    "account": "Medical Supplies/Materials & Consumable ",
    "gst": "GST on Expense",
    "notes": "sometimes GST free depending o what supplies bought"
  },
  {
    "id": "00361",
    "supplier": "Initial C technology",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00362",
    "supplier": "Ink master",
    "code": "461",
    "account": "Printing and Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00363",
    "supplier": "Ink Station",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00364",
    "supplier": "INNSIDE MILANO TORRE . MILANO ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00365",
    "supplier": "Inspire Church",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00366",
    "supplier": "Insurance Australia Ltd",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00367",
    "supplier": "Insurance Australia Ltd",
    "code": "433",
    "account": "Insurance - General",
    "gst": "GST on Expense",
    "notes": "can be coded to MV - Insurance"
  },
  {
    "id": "00368",
    "supplier": "Insurance Commis",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00369",
    "supplier": "Integral Service Group",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00370",
    "supplier": "Interest",
    "code": "437",
    "account": "Interest Expense",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00371",
    "supplier": "International Fund for Animal Welfare",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00372",
    "supplier": "International Transaction Fees",
    "code": "404",
    "account": "Bank Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00373",
    "supplier": "Intuit Australia",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00374",
    "supplier": "IPN Income",
    "code": "201.3",
    "account": "IPN Medical Cent Wyndham GST Free Income  ",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00375",
    "supplier": "IPN Service Fees",
    "code": "438",
    "account": "Service Fees - IPN",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00376",
    "supplier": "Islamic Relief Australia",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00377",
    "supplier": "J2 EFAX Plus Services",
    "code": "461",
    "account": "Printing and Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00378",
    "supplier": "Jamie E Lee Murfet",
    "code": "449.07",
    "account": "MV1 Repirs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00379",
    "supplier": "Jani-King Pty Ltd",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00380",
    "supplier": "JASON WINDOWS WELSHPOOL AUS",
    "code": "416.02",
    "account": "Asset - Immediate write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00381",
    "supplier": "Jaycar Electronics",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00382",
    "supplier": "JB Hi Fi",
    "code": "416.02",
    "account": "Assets - Immediate Write Off",
    "gst": "GST on Expense",
    "notes": "can also be coded to Computer Expenses"
  },
  {
    "id": "00383",
    "supplier": "Jenian Kernour Ltd - Castlemaine Clinic Rent",
    "code": "469",
    "account": "Rent",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00384",
    "supplier": "JETAWAY AIRPORT PARK",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00385",
    "supplier": "JETSTAR MELBOURNE ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00386",
    "supplier": "JJ Waste",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00387",
    "supplier": "JOONDALUP MACWORX ",
    "code": "416.02",
    "account": "Asset - Immediate write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00388",
    "supplier": "Joy Melbourne Inc",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00389",
    "supplier": "Just a Little Cafe Piccadilly ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00390",
    "supplier": "K & B Cleaning",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00391",
    "supplier": "KARRINYUP FRESH PTY ",
    "code": "429",
    "account": "General Expenses",
    "gst": "GST on Expense",
    "notes": "Alternative: Office Expense"
  },
  {
    "id": "00392",
    "supplier": "Kia",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00393",
    "supplier": "Kilmore",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00394",
    "supplier": "Kinet",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "can be drawings - depends to client"
  },
  {
    "id": "00395",
    "supplier": "Koala Furniture",
    "code": "416.02",
    "account": "Asset - Immediate Write Off",
    "gst": "GST on Expense",
    "notes": "We can also use Furniture & Fixtures account"
  },
  {
    "id": "00396",
    "supplier": "La Pizza Trattoria",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If minimal amount but if huge like full family meal, drawings"
  },
  {
    "id": "00397",
    "supplier": "La Trobe Regional",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "MV - Insurance",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00398",
    "supplier": "Lancini Property",
    "code": "469",
    "account": "Rent",
    "gst": "GST on Expense",
    "notes": "depends - can be electricity / other rental outgoings"
  },
  {
    "id": "00399",
    "supplier": "LaTrobe Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00400",
    "supplier": "Leukaemia Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "Depends (QLD branch is Non-DGR, THE LEUKAEMIA FOUNDATION OF AUSTRALIA LIMITED is DGR)"
  },
  {
    "id": "00401",
    "supplier": "LHS Limited Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00402",
    "supplier": "Liberty Oil",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00403",
    "supplier": "Lifeline Australia",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00404",
    "supplier": "Lifestyle Medicine",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00405",
    "supplier": "Limitless Auto Claremont",
    "code": "449.07",
    "account": "M/V 1. - Repairs and Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00406",
    "supplier": "Linkt",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\"  or Parking & Tolls - if there is an account like this"
  },
  {
    "id": "00407",
    "supplier": "Livingstone International",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00408",
    "supplier": "LMT surgical PTY LTD",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00409",
    "supplier": "Logitech",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00410",
    "supplier": "Looksmart Retail PTY ",
    "code": "464",
    "account": "Protective Clothing and Uniforms ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00411",
    "supplier": "Loom Subscription",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00412",
    "supplier": "Lori Neilsen - Photography",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00413",
    "supplier": "Lung Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00414",
    "supplier": "Lush",
    "code": "453",
    "account": "Office Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00415",
    "supplier": "M & R Insurance Brokers Pty Ltd",
    "code": "433",
    "account": "Insurance - General",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00416",
    "supplier": "M E Research Solution",
    "code": "415",
    "account": "Conferences and Seminars",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00417",
    "supplier": "M N Pharma",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00418",
    "supplier": "M2 Commander",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00419",
    "supplier": "MAA INTERNATIONAL",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00420",
    "supplier": "Maccabi Australia",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00421",
    "supplier": "Magic Hand",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00422",
    "supplier": "Mail Redirection Serv Melbourne",
    "code": "460",
    "account": "Postage",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00423",
    "supplier": "MANLY PHARMA 1 PTY LTD",
    "code": "448.1",
    "account": "Medical Expenses",
    "gst": "GST on Expense",
    "notes": "Alternative: Materials and Consumables"
  },
  {
    "id": "00424",
    "supplier": "MAX&CO.MILANO ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00425",
    "supplier": "Mazda",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free, can be coded to \"M/V - Repairs & Maintenance\""
  },
  {
    "id": "00426",
    "supplier": "MCA - WIPP",
    "code": "255",
    "account": "Government Grants",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00427",
    "supplier": "MCA-PIP",
    "code": "255",
    "account": "Government Grants Received/PIP Grants",
    "gst": "BAS Excluded",
    "notes": "Should not be part of G1"
  },
  {
    "id": "00428",
    "supplier": "Mcafee",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "Antivirus protection"
  },
  {
    "id": "00429",
    "supplier": "Mcare Benefits",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00430",
    "supplier": "McFarlane Medical and Scientific",
    "code": "447",
    "account": "Medical Supplies/Materials & Consumable ",
    "gst": "GST on Expense",
    "notes": "sometimes GST free depending o what supplies bought"
  },
  {
    "id": "00431",
    "supplier": "McKenzie Davey Pharmacy",
    "code": "447",
    "account": "Medical Supplies/Materials & Consumable ",
    "gst": "GST on Expense",
    "notes": "sometimes GST free depending o what supplies bought"
  },
  {
    "id": "00432",
    "supplier": "MDA NATIONAL INS P/L WEST PERTH AUS",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00433",
    "supplier": "MDA National Insurance",
    "code": "433",
    "account": "Insurance - General",
    "gst": "GST on Expense",
    "notes": "can be coded as \"Medical Indemnity Insurance\""
  },
  {
    "id": "00434",
    "supplier": "MEDCAST Critical Care",
    "code": "462",
    "account": "Professional Development",
    "gst": "GST on Expense",
    "notes": "can be coded as \"Medical Indemnity Insurance\" (Partly with GST, partly no GST)"
  },
  {
    "id": "00435",
    "supplier": "Medecins Sans Frontieres Australia Limited",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00436",
    "supplier": "Medfin Australia",
    "code": "None",
    "account": "Loan Account",
    "gst": "BAS Excluded",
    "notes": "usually loan payments - sign to ask for loan docs or confirm with client"
  },
  {
    "id": "00437",
    "supplier": "Medibank Private",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00438",
    "supplier": "MEDICAL ASS",
    "code": "450",
    "account": "Medical Supplies",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00439",
    "supplier": "Medical Consumable",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "Can also be coded to Materials & Consumables, it depends what account they used in the prev years. Some transactions are GST free."
  },
  {
    "id": "00440",
    "supplier": "Medical Defence",
    "code": "432",
    "account": "Insurance - Medical Indemnity ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00441",
    "supplier": "Medical HQ",
    "code": "232",
    "account": "Patients Fees - GST Free - Medical HQ",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00442",
    "supplier": "Medical Indemnity Protection Society (MIPS)",
    "code": "433",
    "account": "Insurance - Medical Indemnity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00443",
    "supplier": "Medical Suite Cleaning",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00444",
    "supplier": "Medical Waste",
    "code": "495",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00445",
    "supplier": "MEDICALSPECIAL",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00446",
    "supplier": "Medicare",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00447",
    "supplier": "Medigroup Loupes",
    "code": "416.02",
    "account": "Asset Immediate write-off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00448",
    "supplier": "MEDIQ",
    "code": "400",
    "account": "Accounting & Audit Fees",
    "gst": "GST on Expense",
    "notes": "If the amount is different from the monthly repayment, ask a copy of invoice to Vikas.\nThere might be some legal fees included in the invoice."
  },
  {
    "id": "00449",
    "supplier": "MEDIQ Legal",
    "code": "441",
    "account": "Legal Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00450",
    "supplier": "MediScrubs",
    "code": "464",
    "account": "Protective Clothing & Uniforms",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00451",
    "supplier": "Medshop",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "Can also be coded to Materials & Consumables, it depends what account they used in the prev years. Some transactions are GST free."
  },
  {
    "id": "00452",
    "supplier": "Melanoma Institure Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00453",
    "supplier": "Melb onstreet Parking",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00454",
    "supplier": "MELVILLE TOYOTA MYAREE AUS",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00455",
    "supplier": "Mental Health Academy",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Not DGR"
  },
  {
    "id": "00456",
    "supplier": "Menulog",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If not business related, put to Drawings."
  },
  {
    "id": "00457",
    "supplier": "Mercedes Benz",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00458",
    "supplier": "Merchant Fee",
    "code": "405",
    "account": "Bank Fees - Merchant Charges",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00459",
    "supplier": "Mercy Health Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00460",
    "supplier": "MetLife",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00461",
    "supplier": "Metro Amcal Pharmacy",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00462",
    "supplier": "Metro Fuel",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00463",
    "supplier": "Microsoft",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00464",
    "supplier": "Midsumma Festival Inc.",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00465",
    "supplier": "Miga Insurance",
    "code": "432",
    "account": "Insurance - Medical Indemnitiy",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00466",
    "supplier": "Mill Pharmacy",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00467",
    "supplier": "MIMS",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "Australia's most trusted and comprehensive drug reference system"
  },
  {
    "id": "00468",
    "supplier": "MIPS",
    "code": "432",
    "account": "Insurance - Medical Indemnity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00469",
    "supplier": "Mister Minit",
    "code": "470",
    "account": "Repair & Maintenance",
    "gst": "GST on Expense",
    "notes": "Mister Minit specialises in the services of Shoe Repairs, Key Cutting, Engraving, Watch Service, Car Keys & Remotes Controls."
  },
  {
    "id": "00470",
    "supplier": "MITO FOUNDATION ",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00471",
    "supplier": "Mitsubishi",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00472",
    "supplier": "MLC Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00473",
    "supplier": "MOBILE EXPERTS WARRIOR BROOKVALE",
    "code": "489.02",
    "account": "Telephone - Mobile",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00474",
    "supplier": "Monash Heath",
    "code": "230/231",
    "account": "Patient Fees - GST/GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00475",
    "supplier": "Monthly Plan Fee",
    "code": "404",
    "account": "Bank Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00476",
    "supplier": "Moranbah Agency",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00477",
    "supplier": "Moranbah Cleaning",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00478",
    "supplier": "More telecom",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00479",
    "supplier": "Motorsport Wheels & Tyres",
    "code": "449.07",
    "account": "MV - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00480",
    "supplier": "Movember Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00481",
    "supplier": "Mulgrave ED Services Pty",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00482",
    "supplier": "Multiple Sclerosis Ltd",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00483",
    "supplier": "Multiple Transfer pay/ Multi Pay",
    "code": "803",
    "account": "Wages Payable",
    "gst": "BAS Excluded",
    "notes": "sometimes it is also payment for AP batch (to matched with the batch payment)"
  },
  {
    "id": "00484",
    "supplier": "Mwave",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00485",
    "supplier": "MYOB",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00486",
    "supplier": "MYTEL Voice Data",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00487",
    "supplier": "National Breast Cancer Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00488",
    "supplier": "National Health Foundation",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00489",
    "supplier": "National Health Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00490",
    "supplier": "National Heart Foundation",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00491",
    "supplier": "Nationwide parking",
    "code": "459",
    "account": "Parking & Tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00492",
    "supplier": "Natus Medical",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00493",
    "supplier": "Navy Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00494",
    "supplier": "Nespresso",
    "code": "None",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "depends on the client - can be drawings"
  },
  {
    "id": "00495",
    "supplier": "Net Focus IT",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00496",
    "supplier": "Network Solutions Australasia",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00497",
    "supplier": "Neverfail",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "water rental"
  },
  {
    "id": "00498",
    "supplier": "News Limited",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00499",
    "supplier": "Newsadds Advertising",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00500",
    "supplier": "Next Generation ",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00501",
    "supplier": "NI Claims",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00502",
    "supplier": "NIB",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00503",
    "supplier": "Nick Scali Perth",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "waiting room chairs"
  },
  {
    "id": "00504",
    "supplier": "Nigel Armadale Armadale AUS",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Food and beverage"
  },
  {
    "id": "00505",
    "supplier": "Nissan",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00506",
    "supplier": "No 1 Casr Wash Sale",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00507",
    "supplier": "Noble Oak Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00508",
    "supplier": "North Shire Automotive",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00509",
    "supplier": "Northbridge pharmacy",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00510",
    "supplier": "Norton",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00511",
    "supplier": "Novotel",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00512",
    "supplier": "NQBE",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00513",
    "supplier": "NRMA",
    "code": "449.03",
    "account": "M/V - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00514",
    "supplier": "NRMA Insurance",
    "code": "432",
    "account": "Insurance - Medical Indemnity ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00515",
    "supplier": "NWHHS ",
    "code": "215",
    "account": "Service Fees Received",
    "gst": "GST on Income",
    "notes": "None"
  },
  {
    "id": "00516",
    "supplier": "OCHRE Health",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00517",
    "supplier": "Office Choice",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "MV - Insurance",
    "notes": "can also be coded as Office expenses"
  },
  {
    "id": "00518",
    "supplier": "Officeworks",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "can also be coded as Office expenses"
  },
  {
    "id": "00519",
    "supplier": "One Path Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00520",
    "supplier": "Opal Cove",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\" "
  },
  {
    "id": "00521",
    "supplier": "Optus",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Internet\" "
  },
  {
    "id": "00522",
    "supplier": "Oracle Studio",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00523",
    "supplier": "Origin gas",
    "code": "419",
    "account": "Power, Heat, Electricity, & Gas",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00524",
    "supplier": "Our Computer Guy",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00525",
    "supplier": "Overdrawn Fee",
    "code": "404",
    "account": "Bank Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00526",
    "supplier": "Ozescribe",
    "code": "448",
    "account": "Medical Transcription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00527",
    "supplier": "Pacific Hotel Cair",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00528",
    "supplier": "Panthur",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00529",
    "supplier": "Paradox Bucharest",
    "code": "486",
    "account": "Security",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00530",
    "supplier": "Parkes Liquid Nitrogen",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00531",
    "supplier": "Parkes Pharmacy",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00532",
    "supplier": "Paystay Parking",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00533",
    "supplier": "Peoplecare Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00534",
    "supplier": "Personal expenses ",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00535",
    "supplier": "Perugino Restaurant",
    "code": "480.01/04",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00536",
    "supplier": "Peter MacCallum Cancer Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00537",
    "supplier": "Pharmacy 4 Less",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00538",
    "supplier": "Pharmacy Phusion",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00539",
    "supplier": "Pharmasave",
    "code": "None",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00540",
    "supplier": "Phoenix Health Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00541",
    "supplier": "Pitstop Carwashing",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00542",
    "supplier": "Plan International",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00543",
    "supplier": "Pline PH",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00544",
    "supplier": "Point Parking Pty Ltd",
    "code": "459",
    "account": "Parking",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00545",
    "supplier": "Police Department",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00546",
    "supplier": "Police Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00547",
    "supplier": "POS",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00548",
    "supplier": "Positive Aid Inc",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00549",
    "supplier": "Post",
    "code": "460",
    "account": "Postage",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00550",
    "supplier": "PPS Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Life Insurance"
  },
  {
    "id": "00551",
    "supplier": "Precision",
    "code": "826",
    "account": "Superannuation Payable",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00552",
    "supplier": "Precision IT",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00553",
    "supplier": "Priceline Pharmacy",
    "code": "217.01",
    "account": "Pharmacy - Service Agreement",
    "gst": "GST on Income",
    "notes": "Clements Group"
  },
  {
    "id": "00554",
    "supplier": "Prime Practice",
    "code": "412",
    "account": "Consulting Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00555",
    "supplier": "Print Together",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00556",
    "supplier": "Project Space",
    "code": "412",
    "account": "Consulting Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00557",
    "supplier": "Proudies Foundation Inc",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00558",
    "supplier": "PUC Enterprises",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00559",
    "supplier": "Pulman Sydney Hyde",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00560",
    "supplier": "Puma Energy",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00561",
    "supplier": "Pymble Ladies' College Building Fund",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00562",
    "supplier": "Q Super Ltd",
    "code": "205",
    "account": "Professional Fees Received",
    "gst": "GST on Income",
    "notes": "Non medicare service charged - FCMP"
  },
  {
    "id": "00563",
    "supplier": "Qantas",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "can also be coded to Travel - Internation depends on the flight destination"
  },
  {
    "id": "00564",
    "supplier": "Qantas FF Firect fee",
    "code": "405",
    "account": "Bank Fees Merchant",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00565",
    "supplier": "QBE",
    "code": "449.03",
    "account": "M/V - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00566",
    "supplier": "QBE insurance",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00567",
    "supplier": "QRIDA 335 Disaster Grant",
    "code": "256",
    "account": "Disaster Recovery Grant (NANE)",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00568",
    "supplier": "Quest Bunbury",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00569",
    "supplier": "R&T Health Fund",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00570",
    "supplier": "RAC Insurance",
    "code": "449.03",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00571",
    "supplier": "RACGP",
    "code": "485",
    "account": "Subcriptions",
    "gst": "GST on Expense",
    "notes": "memberships subscription"
  },
  {
    "id": "00572",
    "supplier": "RACQ",
    "code": "449.03",
    "account": "M/V - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00573",
    "supplier": "RACV",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00574",
    "supplier": "RAM Hill & WAGs PHAQPS Nedlands",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00575",
    "supplier": "RAMSAY",
    "code": "230",
    "account": "Patient Fee - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00576",
    "supplier": "Rapid Proof",
    "code": "447",
    "account": "Materials and Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00577",
    "supplier": "ReachOut Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00578",
    "supplier": "Read a book",
    "code": "448",
    "account": "Magazines, jourmals, periodicals",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00579",
    "supplier": "Realself",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00580",
    "supplier": "Red Cross",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00581",
    "supplier": "Red Kite",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00582",
    "supplier": "Red Spot Express",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00583",
    "supplier": "REDCROSS",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00584",
    "supplier": "Rehabilitation Medicine Society",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00585",
    "supplier": "Reserve Bank Health Provider",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00586",
    "supplier": "Rest Superannuation",
    "code": "826",
    "account": "Superannuation payable",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00587",
    "supplier": "Retro Coffee",
    "code": "480.01",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00588",
    "supplier": "Rick Perry Communications",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "sometimes Telephone - Maintenance (if there's a specific account like this)"
  },
  {
    "id": "00589",
    "supplier": "Ring Central",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00590",
    "supplier": "RISTORANTE IL CESTINOMILANO ITA",
    "code": "494",
    "account": "Travel - International",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00591",
    "supplier": "Riverina",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00592",
    "supplier": "Roman Catholic ",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00593",
    "supplier": "Rooms in Style",
    "code": "401",
    "account": "Advertising",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00594",
    "supplier": "Royal Australasian College of Physicians ",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "Annual Subscription (Subscription),College Fee (Professional Develpment)"
  },
  {
    "id": "00595",
    "supplier": "Royal Australian College of General Practitione",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "If fees: Professional Development"
  },
  {
    "id": "00596",
    "supplier": "Royal Children's Hospital Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00597",
    "supplier": "Royal Flying Doctor Service of Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00598",
    "supplier": "RSEA PTY LTD",
    "code": "464",
    "account": "Protective Clothing and Uniforms ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00599",
    "supplier": "RSPCA ",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00600",
    "supplier": "Rural Aid",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00601",
    "supplier": "Safehouse Locksmith",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "blinds on medical suite"
  },
  {
    "id": "00602",
    "supplier": "Salvation Army",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00603",
    "supplier": "Save the Children",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00604",
    "supplier": "Save the Children Fund",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00605",
    "supplier": "SaveOnIT",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00606",
    "supplier": "SCAD Research Inc Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00607",
    "supplier": "Schmick Car Care",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00608",
    "supplier": "Secure a spot",
    "code": "459",
    "account": "Parking & tolls",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00609",
    "supplier": "Seda Kesingston",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00610",
    "supplier": "Seeing Eye Dogs",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00611",
    "supplier": "Seek ",
    "code": "480.04",
    "account": "Staff Recruitment",
    "gst": "GST on Expense",
    "notes": "If the transaction relates to employee/hiring, Staff recruitment. There are also transactions related to Adverising."
  },
  {
    "id": "00612",
    "supplier": "Seraphim Aviation",
    "code": "314",
    "account": "Charter Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00613",
    "supplier": "Serco Australia",
    "code": "231",
    "account": "Patient Fees - GST",
    "gst": "GST on Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00614",
    "supplier": "Service NSW",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00615",
    "supplier": "ShareGift Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00616",
    "supplier": "Shell",
    "code": "449.01",
    "account": "M/V - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00617",
    "supplier": "Signarama",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "Office expenses if name plates for office are bought , or \nadvertising if they are ask to create a poster/signage for advertisement purposes"
  },
  {
    "id": "00618",
    "supplier": "SIMPLE GOOD LIFE PTY ",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Restaurant"
  },
  {
    "id": "00619",
    "supplier": "Sir Charles Gairdn Nedlands Hospital",
    "code": "459",
    "account": "Parking Fee",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00620",
    "supplier": "Skechers Australia",
    "code": "464",
    "account": "Protective Clothing and Uniforms ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00621",
    "supplier": "Skybus Coach Services",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00622",
    "supplier": "Skyfuel Australia Bendigo",
    "code": "403",
    "account": "Airrplane Running Cost",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00623",
    "supplier": "Slack",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00624",
    "supplier": "Small PDF",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00625",
    "supplier": "Smoke House",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If not business related, put to Drawings."
  },
  {
    "id": "00626",
    "supplier": "South East Water",
    "code": "467",
    "account": "Rates & Taxes",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00627",
    "supplier": "Sparkletown Car wash",
    "code": "449.07",
    "account": "M/V - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00628",
    "supplier": "SPECSAVERS BOORAGOON ",
    "code": "464",
    "account": "Protective Clothing & Uniforms",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00629",
    "supplier": "Spintel Pty Ltd",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00630",
    "supplier": "Spotify",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00631",
    "supplier": "Spotlight",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00632",
    "supplier": "Spotto",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\" "
  },
  {
    "id": "00633",
    "supplier": "SPSP Membership",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00634",
    "supplier": "SRO",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "None"
  },
  {
    "id": "00635",
    "supplier": "SSS Australia",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "Clements Group"
  },
  {
    "id": "00636",
    "supplier": "St John of God (SJOG)",
    "code": "230/231",
    "account": "Patient Fees - GST/GST Free",
    "gst": "GST-Free Income",
    "notes": "check invoice / code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00637",
    "supplier": "St John of God Health Care Inc (SJOG)",
    "code": "469.01",
    "account": "Rent- Others",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00638",
    "supplier": "St John of God MOORABBIN AUS (SJOG)",
    "code": "406",
    "account": "Billing Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00639",
    "supplier": "St Kilda Mums Inc",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00640",
    "supplier": "St Mary's College Scholarship and Bursary Fund",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00641",
    "supplier": "Star Carwash",
    "code": "449.07",
    "account": "MV - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00642",
    "supplier": "Staverton Kindergarten Building Fund",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00643",
    "supplier": "Stellina Brisbane",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If not business related, put to Drawings."
  },
  {
    "id": "00644",
    "supplier": "Steves fine wine ",
    "code": "480.01/03",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00645",
    "supplier": "STRANDBAGS ",
    "code": "416.02",
    "account": "Asset - Immediate write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00646",
    "supplier": "Streammotion PTY LTD",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00647",
    "supplier": "Style Read",
    "code": "464",
    "account": "Protective Clothing & Uniforms",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00648",
    "supplier": "Stylish Clothing Alt Subiaco",
    "code": "464",
    "account": "Protective Clothing and Uniforms ",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00649",
    "supplier": "Subaru",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00650",
    "supplier": "Subway",
    "code": "480.01",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00651",
    "supplier": "Suez Recycling and Recovery Pty Ltd",
    "code": "495",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00652",
    "supplier": "Suite 48",
    "code": "469",
    "account": "Rent",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00653",
    "supplier": "Sultan Parking",
    "code": "459",
    "account": "Parking",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00654",
    "supplier": "SUNA'S Shoes",
    "code": "464",
    "account": "Protective Clothing & Uniforms",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00655",
    "supplier": "Suncorp",
    "code": "449.03",
    "account": "MV - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00656",
    "supplier": "Supagas",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "Some are using Medical Supplies"
  },
  {
    "id": "00657",
    "supplier": "Surgical PTY Ltd",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00658",
    "supplier": "Suzuki",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00659",
    "supplier": "Syber Scripbe",
    "code": "490",
    "account": "Transcription & Dictation Fees",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00660",
    "supplier": "Sydney Children's Hospitals Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00661",
    "supplier": "Sydney Symphony Orchestra Holdings Pty Ltd",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00662",
    "supplier": "Synergy",
    "code": "419",
    "account": "Light, Power, Heating",
    "gst": "GST on Expense",
    "notes": "If Cat 2 Drawings"
  },
  {
    "id": "00663",
    "supplier": "TAC Insurance",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00664",
    "supplier": "TAL Life Limited",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "this is a payment for income protection or life insurance"
  },
  {
    "id": "00665",
    "supplier": "Tan Paediatrics",
    "code": "446",
    "account": "Mangement Fees",
    "gst": "GST on Expense",
    "notes": "Office/Admin Exp"
  },
  {
    "id": "00666",
    "supplier": "Tang Law Pty Ltd",
    "code": "441",
    "account": "Legal expenses",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00667",
    "supplier": "Tasmanian Government",
    "code": "449.01",
    "account": "MV1  Registration",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00668",
    "supplier": "Tattersalls Hotel",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\" "
  },
  {
    "id": "00669",
    "supplier": "Tax Office (ATO)",
    "code": "820.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Drawings if payment to PAYGI.\nOther/possible account to be used:\n820 - GST\n825 - PAYG Withholdings Payable\nIncome Tax Payable\nLoan - inter entity loan - paid on behalf of other entity\nATO-ICA/ATO-ITA - those with payment plan/ previous period outstanding payable"
  },
  {
    "id": "00670",
    "supplier": "TDP Communications Pty Ltd",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "can also be coded as internet or telephone account alone depends on the subscription plan"
  },
  {
    "id": "00671",
    "supplier": "Teachers Federation",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00672",
    "supplier": "Team Medical",
    "code": "450",
    "account": "Materials (Medical Supplies) & Consumables ",
    "gst": "GST on Expense",
    "notes": "Can also be coded to Materials & Consumables, it depends what account they used in the prev years. Some transactions are GST free."
  },
  {
    "id": "00673",
    "supplier": "TearFund Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00674",
    "supplier": "Tego Insurance",
    "code": "432",
    "account": "Insurance - Medical Indemnity",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00675",
    "supplier": "Telco Bella Vista",
    "code": "434",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00676",
    "supplier": "Telcoinabox Operations Pty Limited",
    "code": "489",
    "account": "Telephone",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00677",
    "supplier": "Telehealth",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "Billing software"
  },
  {
    "id": "00678",
    "supplier": "Telstra Corporation Pty Ltd",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "can also be coded as internet or telephone alone depends on the subscription plan"
  },
  {
    "id": "00679",
    "supplier": "Termi Pest",
    "code": "470",
    "account": "Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00680",
    "supplier": "Tesla",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00681",
    "supplier": "Tesla Inc St LeonardsAUS",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00682",
    "supplier": "The Alfred Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00683",
    "supplier": "The Baird Institute",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00684",
    "supplier": "The Bendigo Cleaning Company",
    "code": "408",
    "account": "Cleaning",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00685",
    "supplier": "The Blind Factory",
    "code": "416.02",
    "account": "Asset Immediate Write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00686",
    "supplier": "The Cancer Foundation",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00687",
    "supplier": "The Cheesecake Shop -TorquayAUS",
    "code": "480.01",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00688",
    "supplier": "THE DOCTORS HEAL PROVIDER",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00689",
    "supplier": "THE GOOD GUYSBRIGHTON AUS",
    "code": "416.02",
    "account": "Asset Immediate Write-Off",
    "gst": "GST on Expense",
    "notes": "phone/laptop"
  },
  {
    "id": "00690",
    "supplier": "The Little Yellow",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "this are meals by the staff charge to office"
  },
  {
    "id": "00691",
    "supplier": "The Living Room Warehouse",
    "code": "416.02",
    "account": "Asset Immediate Write off",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00692",
    "supplier": "The Lost Dog's Home",
    "code": "417",
    "account": "Donations",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00693",
    "supplier": "The Orangutan Project",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00694",
    "supplier": "The Ritz Carlton Perth",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00695",
    "supplier": "The Royal Australasian SYDNEY AUS",
    "code": "485",
    "account": "Subscriptions",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00696",
    "supplier": "The Shepherd Centre",
    "code": "417",
    "account": "Donation",
    "gst": "BAS Excluded",
    "notes": "DGR"
  },
  {
    "id": "00697",
    "supplier": "The Sydney Children's Hospitals",
    "code": "417",
    "account": "Donation",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00698",
    "supplier": "Therapeutic Guidelines West Melbour AUS",
    "code": "448",
    "account": "Magazines, Journals & Periodicals",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00699",
    "supplier": "Torquay Hotel",
    "code": "480.01",
    "account": "Staff Amenities",
    "gst": "GST on Expense",
    "notes": "Entity with employees, can also be allocated to Travel if ST - If not business related, put to Drawings"
  },
  {
    "id": "00700",
    "supplier": "Totally Workwear Hop Hoppers Cross",
    "code": "None",
    "account": "Protective Clothing",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00701",
    "supplier": "Tour de Rocks",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00702",
    "supplier": "Toyota",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00703",
    "supplier": "Toyota Insurance",
    "code": "449.03",
    "account": "M/V - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00704",
    "supplier": "TPG TELECOM ",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "can also be coded as internet or telephone account alone depends on the subscription plan"
  },
  {
    "id": "00705",
    "supplier": "Tradie Marketing",
    "code": "401",
    "account": "Advertising",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00706",
    "supplier": "Training Centre Hervey Bay ",
    "code": "480.02",
    "account": "Staff Training",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00707",
    "supplier": "Transaction Fees",
    "code": "404",
    "account": "Bank Fees",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00708",
    "supplier": "Transfer to Offset",
    "code": "890",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Usually transfer personal"
  },
  {
    "id": "00709",
    "supplier": "Transfer to Westpac Choice",
    "code": "890",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Usually transfer personal"
  },
  {
    "id": "00710",
    "supplier": "Transition Medical",
    "code": "480.04",
    "account": "Staff Recruitment",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00711",
    "supplier": "Transport Health Fund",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00712",
    "supplier": "Transport NSW EToll",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "Can be coded to \"Drawing\" "
  },
  {
    "id": "00713",
    "supplier": "TSPHMHC",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00714",
    "supplier": "Twice the Doctor Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00715",
    "supplier": "Tyro Fees",
    "code": "405",
    "account": "Bank Fees - Merchant Charges",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00716",
    "supplier": "Tyro Settlements",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00717",
    "supplier": "UBER Eats",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "If minimal amount but if huge like full family meal, drawings"
  },
  {
    "id": "00718",
    "supplier": "UNI Club of WA Crawley WA",
    "code": "485",
    "account": "Subscription",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00719",
    "supplier": "UNICEF",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00720",
    "supplier": "Unified Health",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "If minimal amount but if huge like full family meal, drawings If not business related, put to Drawings."
  },
  {
    "id": "00721",
    "supplier": "United Australia",
    "code": "449.01",
    "account": "M/V 1. - Fuel & Oil",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00722",
    "supplier": "United Chemist",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00723",
    "supplier": "UnityGen",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00724",
    "supplier": "Urban Anaesthetics",
    "code": "483",
    "account": "Services Fee",
    "gst": "GST on Expense",
    "notes": "sometimes we code it to contractor fees"
  },
  {
    "id": "00725",
    "supplier": "Vascular Foundation Donation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00726",
    "supplier": "Veolia",
    "code": "408",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "Sanitary disposal"
  },
  {
    "id": "00727",
    "supplier": "Very Special Kids",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00728",
    "supplier": "Vetta Creative",
    "code": "401",
    "account": "Advertising and Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00729",
    "supplier": "Vic Roads - Road Assist ",
    "code": "449.07",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00730",
    "supplier": "Vicroads",
    "code": "449.05",
    "account": "M/V - Registration",
    "gst": "GST on Expense",
    "notes": "the vehicle registration is broken up into 3 parts: Registration Fee, TAC Charge and Duty Insurance. Only the TAC Charge includes GST; the other 2 components are GST Free"
  },
  {
    "id": "00731",
    "supplier": "Virgin Australia",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00732",
    "supplier": "Vistaprint Australia",
    "code": "461",
    "account": "Printing & Stationery",
    "gst": "GST on Expense",
    "notes": "Can be advertising e.g business card"
  },
  {
    "id": "00733",
    "supplier": "Vital Medical Supplies",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "can be coded as Medical Supplies"
  },
  {
    "id": "00734",
    "supplier": "VIVA Energy ",
    "code": "419",
    "account": "Electricty",
    "gst": "GST on Expense",
    "notes": "if Cat 2 - Drawings"
  },
  {
    "id": "00735",
    "supplier": "Vodafone",
    "code": "489",
    "account": "Telephone & Internet",
    "gst": "GST on Expense",
    "notes": "can also be coded as internet or telephone account alone depends on the subscription plan"
  },
  {
    "id": "00736",
    "supplier": "Volkswagen",
    "code": "730",
    "account": "Motor Vehicle",
    "gst": "GST on Expense",
    "notes": "depends on the car limit, excess over limit is gst free"
  },
  {
    "id": "00737",
    "supplier": "Volkswagen Assist",
    "code": "449.07",
    "account": "M/V 1. - Insurance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00738",
    "supplier": "VTA Benefits",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00739",
    "supplier": "Warner & W Medical Supplies",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00740",
    "supplier": "Warner & Webster Pty Ltd",
    "code": "447",
    "account": "Medical Supplies/Materials & Consumable ",
    "gst": "GST on Expense",
    "notes": "sometimes GST free depending o what supplies bought"
  },
  {
    "id": "00741",
    "supplier": "Wash Management",
    "code": "495",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00742",
    "supplier": "Waste Collection",
    "code": "495",
    "account": "Waste Removal",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00743",
    "supplier": "WATER CORP",
    "code": "467",
    "account": "Rates & Taxes",
    "gst": "GST-Free Expense",
    "notes": "If Cat 2 Drawings"
  },
  {
    "id": "00744",
    "supplier": "Water-water",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Usually for investment property , not business related"
  },
  {
    "id": "00745",
    "supplier": "Watoto Foundation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00746",
    "supplier": "Waves Carwash",
    "code": "449.07",
    "account": "MV - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00747",
    "supplier": "We Wash U",
    "code": "449.07",
    "account": "M/V 1. - Repairs & Maintenance",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00748",
    "supplier": "Webjet Melbourne",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00749",
    "supplier": "Wee Medical",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00750",
    "supplier": "Wellington Day Hospital Pty Ltd",
    "code": "230",
    "account": "Patient fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "None"
  },
  {
    "id": "00751",
    "supplier": "Wellington Day Hospital Pty Ltd",
    "code": "483.01",
    "account": "Services fees - Wellington",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00752",
    "supplier": "Wesfarmers ST",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00753",
    "supplier": "Westfund Ltd",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00754",
    "supplier": "WHO GIVES A CRAP",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "Toilet papers"
  },
  {
    "id": "00755",
    "supplier": "Wi Sky",
    "code": "434",
    "account": "Internet",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00756",
    "supplier": "Wilson Parking",
    "code": "459",
    "account": "Parking",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00757",
    "supplier": "Winning AU ",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00758",
    "supplier": "Woolworths",
    "code": "453",
    "account": "Office Expenses",
    "gst": "GST on Expense",
    "notes": "can be coded as Drawings"
  },
  {
    "id": "00759",
    "supplier": "Workcover Qld",
    "code": "433.02",
    "account": "Insurance - Workers Compensation",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00760",
    "supplier": "Workwealth",
    "code": "464",
    "account": "Protective Clothing & Uniforms",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00761",
    "supplier": "World Animal Protection",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00762",
    "supplier": "World Vision Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00763",
    "supplier": "World\u2019s Greatest Shave Donation",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00764",
    "supplier": "WWF Australia",
    "code": "417",
    "account": "Donations",
    "gst": "GST-Free Expense",
    "notes": "DGR"
  },
  {
    "id": "00765",
    "supplier": "www. Podium. com",
    "code": "401",
    "account": "Advertising & Promotion",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00766",
    "supplier": "Xchanging",
    "code": "433.02",
    "account": "Insurance - Workers Compensation",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00767",
    "supplier": "Xchanging Integr",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00768",
    "supplier": "Xero",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00769",
    "supplier": "Xestro Pty Ltd",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00770",
    "supplier": "Xgimi",
    "code": "416.01/02",
    "account": "Asset Immediate Writeoff",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00771",
    "supplier": "XRX Business Centre",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00772",
    "supplier": "YES IT",
    "code": "414",
    "account": "Computer Expense",
    "gst": "GST on Expense",
    "notes": "If recurring, computer expense. If not, Asset Immediate W/Off or Computer Equipment then depreciate"
  },
  {
    "id": "00773",
    "supplier": "Yes Medical",
    "code": "447",
    "account": "Materials & Consumables",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00774",
    "supplier": "Yoshino Sushi",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "food"
  },
  {
    "id": "00775",
    "supplier": "ZLR*Noodle Box - MalvernAUS",
    "code": "493",
    "account": "Travel - National",
    "gst": "GST on Expense",
    "notes": "None"
  },
  {
    "id": "00776",
    "supplier": "Zoom.us",
    "code": "414",
    "account": "Computer Expenses",
    "gst": "GST-Free Expense",
    "notes": "None"
  },
  {
    "id": "00777",
    "supplier": "Zurich Australia",
    "code": "230",
    "account": "Patient Fees - GST Free",
    "gst": "GST-Free Income",
    "notes": "code to income holding account if RCTI service fee model (MT receives patient fees then net transferred to doctor)"
  },
  {
    "id": "00778",
    "supplier": "Zurich Life",
    "code": "890.04",
    "account": "Drawings",
    "gst": "BAS Excluded",
    "notes": "Income Protection and Life Insurance"
  }
]
