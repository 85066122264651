import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';
import './App.css';
import { data } from './bookkeeping';
import logo from './images/mediq.png';
import wizard from './images/wizard.png';


const App = () =>  {
  // remember the user login status and who is the user
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [fontSize, setFontSize] = useState(100);

  const handleLogin = async response => {
    const decodedToken = jwt_decode(response.credential)
    setUser(decodedToken);
    setEmail(decodedToken.email)
    console.log("Google Account:", decodedToken.name, decodedToken.email)

    await fetch(`https://us-central1-bookkeeping-6be03.cloudfunctions.net/app/QueryAccessRecord?username=${decodedToken.email}&timestamp=${Date.now()}`);
    // await fetch(`http://127.0.0.1:5001/bookkeeping-6be03/us-central1/app/QueryAccessRecord?username=${decodedToken.email}&timestamp=${Date.now()}`);

    document.getElementById('openForm').hidden = false;
  }

  const handleLogout = () => {
    console.log("Logged out successfully!")
    setUser("");
    document.getElementById('openForm').hidden = true;
  }

  const [value, setValue] = useState('');
  const [dataSource, setDataSource] = useState(data);
  const [tableFilter, setTableFilter] = useState([]);
  // all checkboxes are on in default except GST
  const [checkboxFilter, setCheckbox] = useState(
    {id: true, supplier: true, code: true, account: true, gst: false, notes: true});
  // all sort buttons are in 'asc'
  const [sorting, setSorting] = useState(
    {id: 'desc', supplier: 'desc', code: 'desc', account: 'desc', gst: 'desc', notes: 'desc'});
  
  
  // update the checkbox filter
  const handleCheckboxChange = e => {
    setCheckbox({...checkboxFilter, [e.target.value]: e.target.checked});
  }

  // update the table from the search bar value
  const filterData = e => {
    if (e.target.value.length >= 3) {
      setValue(e.target.value);

      // when search is not empty, search the keyword in all columns of each row
      const filterTable = dataSource.filter(object => 
        // get the json object keys and search one by one
        Object.keys(object).some(key => {
          // check is null or not
          if (checkboxFilter[key]) {
            // string format the result to avoid error
            return String(object[key]).toLowerCase().includes(e.target.value.toLowerCase())
          }
          return false
        }
      ));

      setTableFilter([...filterTable]);
    }
    else {
      setValue(e.target.value);
      setDataSource([...dataSource]);
      setTableFilter([]);
    }
  }

  // check the key of column, reverse the sorting
  const handleSort = key => {
    if (sorting[key] === 'asc') {
      setSorting({...sorting, [key]: 'desc'});

      tableFilter.sort((a, b) => {
        if (a[key].toString().toLowerCase() < b[key].toString().toLowerCase()) return -1;
        if (a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) return 1;
        return 0;
      });
    } 
    else {
      setSorting({...sorting, [key]: 'asc'});

      tableFilter.sort((a, b) => {
        if (a[key].toString().toLowerCase() < b[key].toString().toLowerCase()) return 1;
        if (a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) return -1;
        return 0;
      });
    }

    setTableFilter([...tableFilter]);
  };

  const handleFontSize = option => {
    let newFontSize;

    if (option === 'bigger' && fontSize < 200) {
      newFontSize = fontSize + 10;
      document.getElementById('DataTable').style.fontSize = newFontSize.toString() + '%';
      setFontSize(newFontSize);
    }
    else if (option === 'smaller' && fontSize > 80){
      newFontSize = fontSize - 10;
      document.getElementById('DataTable').style.fontSize = newFontSize.toString() + '%';
      setFontSize(newFontSize);
    }
  }

  const handleShowForm = (supplier, code, account, gst, notes, brandNewRecord) => {
    document.getElementById('requestForm').classList.add('open');
    document.getElementById('supplierForm').value = supplier;
    document.getElementById('accountCodeForm').value = code;
    document.getElementById('accountNameForm').value = account;
    document.getElementById('gstCodeForm').value = gst;
    document.getElementById('noteForm').value = notes;
    document.getElementById('newRecordCheckbox').checked = brandNewRecord;
  };

  const handleCloseForm = () => {
    document.getElementById('requestForm').classList.remove('open');
    document.getElementById('supplierForm').value = '';
    document.getElementById('accountCodeForm').value = '';
    document.getElementById('accountNameForm').value = '';
    document.getElementById('gstCodeForm').value = '';
    document.getElementById('noteForm').value = '';
    document.getElementById('reasonForm').value = '';
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const checkbox = document.getElementById('newRecordCheckbox');
    
    const data = {
      "supplier": event.target[0].value,
      "code": event.target[1].value,
      "name": event.target[2].value,
      "state": event.target[3].value,
      "note": event.target[4].value,
      "reason": event.target[5].value,
      "existingRecord": checkbox.checked,
      "email": email,
    };

    await fetch('https://us-central1-bookkeeping-6be03.cloudfunctions.net/app/SendEmail', {
    // await fetch('http://localhost:5001/bookkeeping-6be03/us-central1/app/SendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(document.getElementById('requestForm').classList.remove('open'));
  }

  return (
    <div>
      <div className='flex-container'>
        <img id='Logo' src={ logo } alt='MEDIQ Financial' />
        <img id='Wizard' src={ wizard } alt='Ledger Wizard' />
        <div id="SearchBar" className="container input-group">
          <input type="text" className="form-control" placeholder="keyword with at least 3 characters..."
            value={value}
            onChange={filterData}
          />
        </div>

        <button id='openForm' className="btn btn-success" hidden
          onClick={() => handleShowForm('', '', '', '', '', false)}>
          New Update
        </button>

        {user ? 
          (<button class="SignOut" className="btn btn-success" onClick={handleLogout}>Logout</button>)
          :
          <GoogleLogin 
            onSuccess={handleLogin}
            onError = {() => {
              console.log('Login Failed!')
            }}
          />
        }
      </div>

      {/* form */}
      <div id="requestForm">
        <h3 className="requestFormTitle">Ledger Wizard Update Request</h3>
        <form onSubmit={handleSubmit} class="row g-3">
          <div class="col-md-4">
            <label htmlFor="supplierForm" class="form-label">Supplier:</label>
            <input type="text" class="form-control" id="supplierForm" name="supplierForm" required />
          </div>
          <div class="col-md-4">
            <label htmlFor="accountCodeForm" class="form-label">Xero Account Code:</label>
            <input type="text" class="form-control" id="accountCodeForm" name="accountCodeForm" required />
          </div>
          <div class="w-100"></div>
          <div class="col-md-4">
            <label htmlFor="accountNameForm" class="form-label">Xero Account Name:</label>
            <div class="input-group">
              <input type="text" class="form-control" id="accountNameForm" name="accountNameForm" required />
            </div>
          </div>
          <div class="col-md-4">
            <label htmlFor="gstCodeForm" class="form-label">GST State:</label>
            <select class="form-select" id="gstCodeForm" name="gstCodeForm" required>
              <option value="GST on Income">GST on Income</option>
              <option value="GST-Free Income">GST-Free Income</option>
              <option value="GST on Expense">GST on Expense</option>
              <option value="GST-Free Expense">GST-Free Expense</option>
              <option value="BAS Excluded">BAS Excluded</option>
              <option value="MV - Insurance">MV - Insurance</option>
            </select>
          </div>
          <div class="w-100"></div>
          <div class="col-md-5">
            <label htmlFor="noteForm" class="form-label">Note:</label>
            <textarea class="form-control" id="noteForm" name="noteForm" rows="6" required />
          </div>
          <div class="col-md-5">
            <label htmlFor="reasonForm" class="form-label">Update Reasons:</label>
            <textarea class="form-control" id="reasonForm" name="reasonForm" rows="6" required />
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="newRecordCheckbox" name="newRecordCheckbox" />
            <label class="form-check-label" for="flexCheckDefault">
              Is this update request for an existing record?
            </label>
          </div>
          <div class="col-12">
            <button class="submitForm btn btn-primary" type="submit">Submit Request</button>
            <button class="submitForm btn btn-danger" type="button"
              onClick={() => handleCloseForm()}>
              Cancel
            </button>
          </div>
        </form>
      </div>

      {/* data table */}
      {
        // check already sign-in or not
        Object.keys(user).length !== 0
        &&
        <div id="DataTable" className="container mt-5">
          <table className="table table-striped">
            <thead className="thead-light">
              <tr>              
                {
                  Object.keys(checkboxFilter).map((key, index) => 
                    <th key={index}>
                      <div className="form-check form-switch">
                        <input id={key} className="form-check-input" 
                          type="checkbox" name={key} value={key}
                          defaultChecked={checkboxFilter[key]} disabled={key === "gst"}
                          onChange={e => handleCheckboxChange(e) }
                        />
                        {/* print the sorting arrow */}
                        <i 
                          className={`fa-solid fa-arrow-${(sorting[key] === 'asc' ? 'up' : 'down')}`} 
                          onClick={() => handleSort(key)}
                        />
                      </div>
                      {/* print the labels on table header */}
                      <div>
                        <label className="form-check-label" htmlFor={key}>{key.toUpperCase()}</label>
                      </div>
                    </th>
                  )
                }
                {/* fill up the missing header space of update button */}
                <th id="FontSize">
                  <p>Size</p>
                  <i class="fa-solid fa-a" onClick={() => handleFontSize('smaller')} />
                  <i id="BiggerFont" class="fa-solid fa-a" onClick={() => handleFontSize('bigger')} />
                </th>
              </tr>
            </thead>
            
            <tbody>
            {
              tableFilter.map(item =>
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.supplier}</td>
                  <td>{item.code}</td>
                  <td>{item.account}</td>
                  <td>{item.gst}</td>
                  <td>{item.notes}</td>
                  <td><button class="btn btn-info btn-sm" 
                        onClick={() => handleShowForm(
                          item.supplier, item.code, item.account, item.gst, item.notes, true)}>
                        Update
                      </button>
                  </td>
                </tr>
              )
            }
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default App;
